<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="52.5%"
    @ok="handleOk" @cancel="handleCancel">
    <div class="clearfix table-tools">
        <div class="buttons">
          <a-form layout='inline'>
            <a-form-item>
              <a-button icon="plus" type="primary" @click="showEditModal()">添加课程</a-button>
            </a-form-item>
          </a-form>
        </div>
        <div class="search">
          <a-form layout='inline'>
            <a-form-item>
              <a-tooltip placement="topLeft" >
                <template slot="title">
                  <span>不对外公开</span>
                </template>
                <a-select allowClear v-model="searchData.is_private" placeholder="请选择不对外公开" style="width: 160px">
                  <a-select-option v-for="(d, index) of isPrivate" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
                </a-select>
              </a-tooltip>
            </a-form-item>
            <a-form-item>
              <a-tooltip placement="topLeft" >
                <template slot="title">
                  <span>课程状态</span>
                </template>
                <a-select allowClear v-model="searchData.course_status" placeholder="请选择课程状态" style="width: 160px">
                  <a-select-option :value="1">有效</a-select-option>
                  <a-select-option :value="0">无效效</a-select-option>
                </a-select>
              </a-tooltip>
            </a-form-item>
            <a-form-item>
              <a-button type="primary" @click="searchList">搜索</a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
      <div class="table">
        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
        <a-table size="small" :pagination="false" :bordered='false' rowKey="course_id"
          :columns="columns" :dataSource="list">

          <!-- <template slot="isPrivate" slot-scope="text, record">
            <span v-if="text===1">不对外开放</span>
            <span v-else>对外开放</span>
          </template> -->

          <template slot="course_status" slot-scope="text, record">
            <a-switch checkedChildren="有效" unCheckedChildren="无效" :checked="record.course_status == 1" @change="changeStatus(record)"/>
          </template>

          <template slot="action" slot-scope="text,record">
            <a href="javascript:;" @click="showEditModal(record)">编辑</a>
          </template>
        </a-table>
      </div>
      <div class="page">
        <a-pagination
          :pageSizeOptions="pageSizeOptions"
          :total="pageParams.totalCount"
          showSizeChanger
          :pageSize="pageParams.perPage"
          v-model="current"
          @change="changePage"
          @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
        >
            <template slot='buildOptionText' slot-scope='props'>
              <span v-if="props.value!=='100'">{{props.value}}条/页</span>
              <span v-if="props.value==='100'">100条/页</span>
            </template>
          </a-pagination>
      </div>
    <editModal v-if="editVisible" :course_cate_id="item.id" :item="modalData"/>
  </a-modal>
</template>

<script>
const columns = [
  { title: '课序', dataIndex: 'course_sort', key: 'name'},
  { title: '课程名称', dataIndex: 'course_name', key: 'age'},
  { title: '课程时间(分钟)', dataIndex: 'course_times', key: '1',align:"center"},
  { title: '课时消耗', dataIndex: 'cost_times', key: '2' ,align:"center"},
  { title: '单课付费', dataIndex: 'course_single_fee_name', key: 'course_single_fee_name' ,align:"center"},
  { title: '课程状态', dataIndex: 'course_status', key: '6',align:"center", scopedSlots: { customRender: 'course_status' } },
  { title: '操作', key: 'operation', width: 92, scopedSlots: { customRender: 'action' }}
]
				
import C_ITEMS from '@/utils/items'
import editModal from './editModal'
import tableMixins from '@/common/mixins/table'
export default {
  name: 'ListModal',
  provide() {
    return {
      parent: this
    }
  },
  components: {
    editModal
  },
  inject: ['parent'],
  props: {
    item: Object
  },

  data() {
    return {
      loading: false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      columns,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      list: [],
      searchData: {
        course_status:1
      },
      isPrivate: C_ITEMS.isPrivate,

      labelCol: { span: 5 },
      wrapperCol: { span: 15 },
      ModalTitle: '',
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),

      editVisible: false,

    }
  },
  mixins:[tableMixins],
  created() {
    this.visible = true
    this.ModalTitle = `课程列表 (${this.item.category_title})`
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    closeSearch() {
      this.visible = false
    },
    async getList() {
      let obj = {}
      let { searchData } = this
      if (searchData) {
        for (let k in searchData) {
          obj[k] = searchData[k]
        }
      }
      this.searchParams.search = obj
      this.searchParams.course_cate_id = this.item.id
      this.loading = true
      let res = await this.$store.dispatch('cCategoryCoursesListAction', { data: this.searchParams })
      this.loading = false
      this.list = res.items
      this.pageParams = res._meta
    },
    handleOk() {
      this.parent.hideListModal(1)
    },
    handleCancel() {
      this.parent.hideListModal(0)
    },

    showEditModal(item) {
      this.modalData = item
      this.editVisible = true
    },
    hideEditModal(type) {
      if (type === 1) {
        this.getList()
      }
      this.editVisible = false
    },
    changeStatus(record){
      if (record.course_status === 1) {
        this.deleteItem(record)
      }else{
        this.recoveryItem(record)
      }
    },
    deleteItem(item) {
      let that = this
      this.$confirm({
        title: `您确定要删除课程"${item.course_name}"吗？`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('cCategoryCoursesDeleteAction', { data: { course_id: item.course_id } })
            if (res) {
              that.getList()
              resolve(res)
            }
          }).catch(error => console.log(error))
        }
      })
    },
    recoveryItem(item) {
      let that = this
      this.$confirm({
        title: `您确定要恢复课程"${item.course_name}"吗？`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('cCategoryCoursesRecoveryAction', { data: { course_id: item.course_id } })
            if (res) {
              that.getList()
              resolve(res)
            }
          }).catch(error => console.log(error))
        }
      })
    },
  }
}
</script>