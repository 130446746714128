<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="62.5%"
    @ok="handleOk" @cancel="handleCancel">
    <a-form :form="form">
      <a-form-item>
          <div>
            <a-button @click="addTicket(1)" icon="plus-circle">课时收费</a-button>
            <!-- <a-button @click="addTicket(2)" icon="plus-circle" style='margin-left:10px'>按月收费</a-button> -->
          </div>
          <div class="table">
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
            <a-table size="small" :pagination="false" :bordered='false' rowKey='fee_id'
              :columns="columns" :dataSource="list">
                <template slot="name" slot-scope="text,record">
                  <a-input :value="text"    @change="e=>handleChange(e,record,'fee_title',0)" placeholder="收费名称" />
                </template>
                <template slot="buy" slot-scope="text,record,index">
                  <div v-if="record.fee_type == 10 || record.fee_type == 40">
                    <a-input-number style='width:72px' @change="e=>handleNumber(e,record,'times',1)" :value="record.times" :min="0" placeholder="购买课时"/><span class="course-margin">课时</span>
                  </div>
                  <div v-else>
                    <a-input-number style='width:72px' @change="e=>handleNumber(e,record,'month',1)" :value="record.month" :min="0" placeholder="购买月数"/><span class="course-margin">月</span>
                  </div>
                </template>
                <template slot="gift_times" slot-scope="text,record,index">
                  <div v-if="record.fee_type == 10 || record.fee_type == 40">
                    <a-input-number style='width:72px' @change="e=>handleNumber(e,record,'gift_times',2)" :value="record.gift_times" :min="0" placeholder="赠送课时"/><span class="course-margin">课时</span>
                  </div>
                  <div v-else>
                    <a-input-number style='width:72px' @change="e=>handleNumber(e,record,'gift_day',2)" :value="record.gift_day" :min="0" placeholder="赠送天数"/><span class="course-margin">天</span>
                  </div>
                </template>
                <template slot="price" slot-scope="text,record,index">
                  <a-input-number :id="'price' + index" @change="e=>handleChange(e,record,'price',1)" :value="text" :min="0" placeholder="请输入"/>
                </template>
                <template slot="unit_price" slot-scope="text,record,index">
                  <div v-if="record.fee_type == 10 || record.fee_type == 40">
                    <span>{{record.unit_price}}</span><span style="margin-left:5px"></span>
                  </div>
                  <div v-else>
                    <span>{{record.unit_price}}</span><span style="margin-left:5px">/ 月</span>
                  </div>
                </template>
                <template slot="is_trial" slot-scope="text,record,index">
                  <a-checkbox :checked="record.is_trial ==1" @change="e=>handleCheckChange(e,record)"></a-checkbox>
                </template>
                <template slot="action" slot-scope="text,record,index">
                    <a-popconfirm
                      title="您确认删除该收费"
                      ok-text="确认"
                      cancel-text="取消"
                      @confirm="deleteItem(record,index)"
                      >
                          <a href="#">删除</a>
                    </a-popconfirm>
                </template>
            </a-table>
        </div>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
const columns = [
  { title: '收费类型', width:'100px', dataIndex: 'fee_type', key: 'fee_type' , scopedSlots: { customRender: 'fee_type' },customRender:(value, record, index)=>{
      const obj = {
            children: record.fee_type == 10 || record.fee_type == 40 ? '课时收费':'按月收费' ,
            attrs: {},
          };
          if (record.rowSpan) {
            obj.attrs.rowSpan = record.rowSpan
          }else{
            obj.attrs.rowSpan = 0
          }
          return obj
  }},
  { title: '收费名称', width:'280px', dataIndex: 'fee_title', key: 'fee_title', scopedSlots: { customRender: 'name' }},
  { title: '购买', dataIndex: 'buy', key: 'buy', scopedSlots: { customRender: 'buy' }},
  { title: '赠送', dataIndex: 'gift_times', key: 'gift_times' , scopedSlots: { customRender: 'gift_times' }},
  { title: '收费金额', dataIndex: 'price', key: 'price',scopedSlots: { customRender: 'price' }},
  { title: '单价', dataIndex: 'unit_price', key: 'unit_price',scopedSlots: { customRender: 'unit_price' }},
  { title: '体验', dataIndex: 'is_trial', key: 'is_trial',scopedSlots: { customRender: 'is_trial' },align:"center"},
  { title: '操作', key: 'operation', width:'80px',scopedSlots: { customRender: 'action' },align:"right"},
]
export default {
  name: 'EditModal',
  inject: ['parent'],
  props: {
    item: Object
  },

  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 15 },
      ModalTitle: '课程收费',
      visible: false,
      confirmLoading: false,
      columns,
      list:[],
      listLength:0,
      updateNum:0,
      form: this.$form.createForm(this),
      courseFee_course:[],
      courseFee_month:[],
    }
  },

  async created() {
    this.visible = true
    this.getList()
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    async getList() {
      this.loading = true
      let res = await this.$store.dispatch('courseFeeAction', { data: {course_cate_id:this.item.id}})
      this.loading = false
      this.listLength = res.items.length
      this.listSort(res.items)
      this.pageParams = res._meta
    },
    async handleOk(record) {
      let list = [...this.list]
      for(let i=0; i<list.length; i++){
        if(!list[i].fee_title){
          this.$message.warning(`请填写第${index + 1}行的收费名称`)
          return false
        }
        if(list[i].fee_id < 0){
          delete list[i].fee_id
        }
      }
      this.confirmLoading = true 
      await this.$store.dispatch('courseFeeUpdateAction', {data:{fee:this.list,course_cate_id:this.item.id}})
        .then(res=>{
          this.getList()
          this.confirmLoading = false
          this.$message.success('操作成功~')
        })
        .catch(err=>{
          this.confirmLoading = false
        })
    },
    handleCancel() {
      this.parent.hideChargeModal(0)
    },
    handleChange(e,record,name,num){
      if(num === 0){
        record[name] = e.target.value
      }else if(num === 1){
        record[name] = e
        if(record.fee_type == 20 || record.fee_type == 30){
          let unit_price = record.price/record.month
          record.unit_price = unit_price.toFixed(2)
        }else{
          let unit_price = record.price / record.times
          record.unit_price = unit_price.toFixed(2)
        }
      }
    },
    handleCheckChange(e,item){
      item.is_trial = e.target.checked? 1 : 0
    },
    addTicket(num){
      this.listLength+=1
      let obj = {
        fee_title:null,
        course_cate_id:this.item.id,
        unit_price:0,
        price:0,
        fee_id:`-${this.listLength}`,
        hide:0,
        fee_status:1
      }
      if(num == 1){
        obj.fee_type = 10
        obj.times = 0
        obj.gift_times = 0
        if(this.courseFee_course.length > 0){
          delete this.courseFee_course[0].rowSpan
        }
        this.courseFee_course.unshift(obj)
      }else{
        obj.fee_type = 20
        obj.month = 0
        obj.gift_day = 0
        if(this.courseFee_month.length > 0){
          delete this.courseFee_month[0].rowSpan
        }
        this.courseFee_month.unshift(obj)
      }
      let list = [...this.courseFee_month,...this.courseFee_course]
      this.listSort(list)
    },
    handleNumber(e,record,name,num){
      record[name] = e || 0
      if(num === 1){
        let unit_price = record.price / record[name]
        record.unit_price = unit_price.toFixed(2)
      }
    },
    async deleteItem(item,index) {
      if(item.fee_id < 0){
        this.list.splice(index,1)
        this.listSort(this.list)
      }else{
        this.list[index].fee_status = 0
        await this.$store.dispatch('courseFeeUpdateAction',{data:{fee:this.list}})
          .then(res=>{
              this.getList()
              this.$message.success('操作成功~')
          })
          .catch(err=>{

          })
      }
      
    },
    listSort(arr){
      let courseFee_course = []
      let courseFee_month = []
      arr.forEach((item,index)=>{
        if(item.fee_type == 20 || item.fee_type == 30){
          courseFee_month.push(item)
        }else{
          courseFee_course.push(item)
        }
      })
      this.courseFee_course = courseFee_course
      this.courseFee_month = courseFee_month
      if(courseFee_course.length > 0){
        courseFee_course[0].rowSpan = courseFee_course.length
      }
      if(courseFee_month.length > 0){
        courseFee_month[0].rowSpan = courseFee_month.length
      }
      this.list = [...courseFee_month, ...courseFee_course]
    }
  }
}
</script>
 <style lang="scss" scoped>
  .add-ticket{
    text-align:center;
    border:1px dashed #e5e6e7;
    cursor:pointer;
    margin-top:20px;
    line-height:30px
  }
  .course-margin{
    margin-left: 10px;
  }
 </style>
