<template>
  <div>
    <a-breadcrumb class="breadcrumb">
      <a-breadcrumb-item>首页</a-breadcrumb-item>
      <a-breadcrumb-item>教务中心</a-breadcrumb-item>
      <a-breadcrumb-item>课程科目</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="main-box">
      <div class="clearfix table-tools">
        <div class="buttons">
          <a-form layout='inline'>
            <a-form-item v-if="canSave">
              <a-button icon="plus" type="primary" @click="showEditModal()">添加科目</a-button>
            </a-form-item>
          </a-form>
        </div>
        <div class="search">
          <a-form layout='inline' @submit="searchList">
            <a-form-item>
              <a-tooltip placement="topLeft" >
                <template slot="title">
                  <span>科目名称</span>
                </template>
                <a-input allowClear v-model.trim="searchData.category_title" placeholder="科目名称" style="width: 160px"/>
              </a-tooltip>
            </a-form-item>
            <a-form-item>
              <a-tooltip placement="topLeft" >
                <template slot="title">
                  <span>科目状态</span>
                </template>
                <a-select allowClear v-model="searchData.category_status" placeholder="请选择科目状态" style="width: 160px">
                  <a-select-option v-for="(d, index) of categoryStatus" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
                </a-select>
              </a-tooltip>
            </a-form-item>
            <a-form-item>
              <a-tooltip placement="topLeft" >
                <template slot="title">
                  <span>是否对外公开</span>
                </template>
                <a-select allowClear v-model="searchData.is_private" placeholder="请选择是否对外公开" style="width: 160px">
                  <a-select-option v-for="(d, index) of isPrivate" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
                </a-select>
              </a-tooltip>
            </a-form-item>
            <a-form-item v-if="canIndex">
              <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
      <div class="table">
        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
        <a-table size="middle" :pagination="false" :bordered='false' rowKey="id"
          :columns="columns" :dataSource="list" :scroll="{ x: 1200 }">
          <template slot="index" slot-scope="text, record , index">
            <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
          </template>
          <template slot="images" slot-scope="text, record">
            <LImg :src="text" style="width: 100px; height: 42px; margin: 0 5px 5px 0"/>
          </template> 

          <template slot="color" slot-scope="text, record,index">
            <swatches
              v-model="record.color"
              show-fallback
              popover-to="left"
              shapes="circles"
              row-length="6"
              @open='openColor(record)'
              @close="saveColor(record)"
            ></swatches>
          </template>
          <template slot="status" slot-scope="text, record">
            <a-switch checkedChildren="有效" unCheckedChildren="无效" :checked="text === 1" @change="changeStatus(record)"/>
          </template>

          
          <template slot="course_count" slot-scope="text, record">
            <a href="javascript:;" @click="showListModal(record)">{{text}}</a>
          </template>


          <template slot="course_fee_count" slot-scope="text, record">
            <a href="javascript:;" @click="showChargeModal(record)">{{text}}</a>
          </template>

          <template slot="private" slot-scope="text, record">
            <span v-if="text == 0">是</span>
            <span v-else>否</span>
          </template>

          <template slot="studio_names" slot-scope="text, record">
              <span v-if="record.studio_names.length === 0">--</span>
              <a-dropdown v-else>
                  <a style="cursor:pointer" class="ant-dropdown-link" @click="e => e.preventDefault()">
                      {{record.studio_names[0]}}<a-icon type="caret-down" />
                      <!-- 等{{record.studio_names.length}}个校区 -->
                  </a>
                  <a-menu style="max-height:400px;overflow-y: auto;" slot="overlay">
                      <a-menu-item style="text-align: center" v-for="(it,j) in record.studio_names" :key="j">
                          {{it}}
                      </a-menu-item>
                  </a-menu>
              </a-dropdown>
          </template>

          <template slot="action" slot-scope="text,record">
            <a href="javascript:;" @click="showChargeModal(record)">课程收费</a>
            <a-divider type="vertical" />
            <a href="javascript:;" @click="showListModal(record)">课程列表</a>
            <a-divider type="vertical" />
            <a v-if="canUpdate" href="javascript:;" @click="showEditModal(record)">编辑</a>
          </template>
        </a-table>
      </div>
      <div class="page">
        <a-pagination
          :pageSizeOptions="pageSizeOptions"
          :total="pageParams.totalCount"
          showSizeChanger
          :pageSize="pageParams.perPage"
          v-model="current"
          @change="changePage"
          @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
        >
            <template slot='buildOptionText' slot-scope='props'>
              <span v-if="props.value!=='100'">{{props.value}}条/页</span>
              <span v-if="props.value==='100'">100条/页</span>
            </template>
          </a-pagination>
      </div>
    </div>
    <editModal v-if="editVisible" :isEdit="isEdit" :item="modalData"/>
    <listModal v-if="listVisible" :item="modalData"/>
    <chargeModal v-if="isChargeModal" :item="chargeData"/>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index', fixed: 'left'},
  { title: '科目图片(尺寸 750*360)', width: '180px', dataIndex: 'category_cover', scopedSlots: { customRender: 'images' }, key: 'name', fixed: 'left' },
  { title: '科目名称', width: '150px', dataIndex: 'category_title', key: 'category_title', fixed: 'left',ellipsis:true},
  { title: '课程收费',width: '80px', dataIndex: 'course_fee_count', key: 'course_fee_count' ,align:'center', scopedSlots: { customRender: 'course_fee_count' }},
  { title: '课程数',width: '80px', dataIndex: 'course_count', key: '1' ,align:'center', scopedSlots: { customRender: 'course_count' }}, 
  { title: '课时消耗',width: '80px', dataIndex: 'course_cost', key: '2' ,align:'center'},
  { title: '单课时长(分钟)',width: '80px', dataIndex: 'course_times', key: '3' ,align:'center'},
  { title: '标注颜色',width: '80px', dataIndex: 'color', key: '6', scopedSlots: { customRender: 'color' },align:"center"},
  { title: '公开',width: '80px', dataIndex: 'is_private', key: '8' , scopedSlots: { customRender: 'private' } },
  { title: '适用校区',width:'150px', dataIndex: 'studio_names', key: 'studio_names' , scopedSlots: { customRender: 'studio_names' } ,align:'center'},
  { title: '科目状态',width: '80px', dataIndex: 'category_status', key: '7', scopedSlots: { customRender: 'status' } },
  { title: '操作', key: 'operation', fixed: 'right', width: 190, scopedSlots: { customRender: 'action' }}
]

import C_ITEMS from '@/utils/items'
import editModal from './editModal'
import chargeModal from './charge'
import listModal from './list/index'
import Swatches from 'vue-swatches'
import "vue-swatches/dist/vue-swatches.min.css"
import authority from '@/common/mixins/authority'
import tableMixins from '@/common/mixins/table'
export default {
  name: 'Contract',
  provide() {
    return {
      parent: this
    }
  },

  components: {
    Swatches,
    editModal,
    listModal,
    chargeModal
  },

  data() {
    return {
      loading: false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      isEdit:0,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      columns,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      list: [],
      current: 1,
      categoryStatus: C_ITEMS.categoryStatus,
      isPrivate: C_ITEMS.isPrivate,
      searchData: {},
      visible: false,
      editVisible: false,
      listVisible: false,
      beforeColor:'',
      chargeData:null,
      isChargeModal:false,
      authType:['orginization','course'],
    }
  },
  mixins: [ authority , tableMixins],

  methods: {
    closeSearch() {
      this.visible = false
    },
    async getList() {
      if(!this.canIndex){
          this.$message.warning('您暂无权限!!')
          return false
      }
      let obj = {}
      let { searchData } = this
      if (searchData) {
        for (let k in searchData) {
          if (k === 'created_at') {
            obj['start_date'] = searchData[k][0]
            obj['end_date'] = searchData[k][1]
          } else {
            obj[k] = searchData[k]
          }
        }
      }
      this.searchParams.search = obj
      this.loading = true
      let res = await this.$store.dispatch('cCategoryListAction', { data: this.searchParams })
      this.loading = false
      this.list = res.items
      this.pageParams = res._meta
    },

    showEditModal(item) {
      if(item){
        this.isEdit = 1
      }else{
        this.isEdit = 0
      }
      this.modalData = item
      this.editVisible = true
    },
    hideEditModal(type) {
      if (type === 1) {
        this.getList()
      }
      this.editVisible = false
    },

    showListModal(item) {
      this.modalData = item
      this.listVisible = true
    },
    hideListModal(type) {
      this.listVisible = false
    },
    showChargeModal(item){
      this.chargeData = item
      this.isChargeModal = true
    },
    hideChargeModal(){
      this.isChargeModal = false
    },

    deleteItem(item) {
      let that = this
      this.$confirm({
        title: `您确定要关闭科目"${item.category_title}"吗？`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            await that.$store.dispatch('cCategoryDeleteAction', { data: { id: item.id } })
            .then(res=>{
              that.$message.success('操作成功~')
              that.getList()
              resolve(res)
            })
            
          }).catch(error => console.log(error))
        }
      })
    },
    recoveryItem(item) {
      let that = this
      this.$confirm({
        title: `您确定要恢复科目"${item.category_title}"吗？`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            await that.$store.dispatch('cCategoryRecoveryAction', { data: { id: item.id } })
            .then(res=>{
              that.$message.success('操作成功~')
              that.getList()
              resolve(res)
            })
          }).catch(error => console.log(error))
        }
      })
    },
    openColor(item){
      this.beforeColor = item.color
    },
    async saveColor(item) {
      if(this.beforeColor == item.color){
        return false
      }
      await this.$store.dispatch(item ? 'cCategoryUpdateAction' : 'cCategoryAddAction', { data: item })
      this.getList()
    },
    changeStatus(record){
      if (record.category_status === 1) {
        this.deleteItem(record)
      } else {
        this.recoveryItem(record)
      }
    }

  }
}
</script>