<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="400px"
    @ok="handleOk" @cancel="handleCancel">
    <a-form :form="form" layout="vertical">
      <a-form-item v-for="(item, index) of formData" :key="index" :label="item.label">
        <a-input v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]"/>
        <a-input-number v-if="item.type === 'number'" v-decorator="[item.name, { rules: item.rules }]" :min="0" :step="1" style="width:100%"/>
        <a-select v-else-if="item.type === 'select-fee'" placeholder="请选择" showSearch :filterOption="filterOption" v-decorator="[item.name, { rules: item.rules }]">
          <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
        </a-select>
        <a-input-number v-if="item.type === 'float'" :precision="1" v-decorator="[item.name, { rules: item.rules }]" :min="0" :step="0.5" style="width:100%"/>
        <a-date-picker v-if="item.type === 'date'" style="width: 100%"  v-decorator="[item.name, { rules: item.rules }]" format="YYYY-MM-DD"/>
        <a-select v-else-if="item.type === 'select'" placeholder="请选择"
           v-decorator="[item.name, { rules: item.rules }]">
          <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
        </a-select>
        <a-textarea v-else-if="item.type === 'textarea'" :rows="4"
           v-decorator="[item.name, { rules: item.rules }]"/>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
const formData = [
  {
    name: 'course_sort',
    label: '课序',
    type: 'number',
    rules: [{ required: true, message: '请输入课序!' }]
  },
  {
    name: 'course_name',
    label: '课程名称',
    type: 'input',
    rules: [{ required: true, message: '请输入课程名称!' }]
  },
  {
    name: 'course_times',
    label: '课程时间(分钟)',
    type: 'number',
    rules: [{ required: true, message: '请输入课程时间(分钟)!' }]
  },
  {
    name: 'cost_times',
    label: '课时消耗',
    type: 'float',
    rules: [{ required: true, message: '请输入课时消耗!' }]
  },
  {
    name: 'course_single_fee_id',
    label: '单课付费',
    type: 'select-fee',
    rules: [{ required: false, message: '请选择单课付费!' }],
    items: {
      data: 'recruitFees',
      label: 'fee_name',
      value: 'fee_id'
    }
  }
  // {
  //   name: 'course_price',
  //   label: '单课付费',
  //   type: 'float',
  //   rules: [{ required: true, message: '请输入单课付费!' }]
  // },
]
export default {
  name: 'EditModal',
  inject: ['parent'],
  props: {
    item: Object,
    course_cate_id:Number
  },

  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 15 },
      ModalTitle: '新增课程',
      visible: false,
      confirmLoading: false,
      formData,
      seleteItems: {
        recruitFees: [],
      },
      form: this.$form.createForm(this),
    }
  },

  async created() {
    this.visible = true
    await this.$nextTick()
    this.getSingleFee(this.course_cate_id)
    if (this.item) {
      this.ModalTitle = '编辑课程'
      let { item } = this
      let formFields = {}
      for (let d of formData) {
        formFields[d.name] = item[d.name]
      }
      this.form.setFieldsValue(formFields)
    }
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    async getSingleFee(course_cate_id) {
      let res = await this.$store.dispatch('cCategoryCoursesSingleFeeAction', { data: { course_cate_id } })
      this.seleteItems.recruitFees = res.data
    },
    async handleOk() {
      this.confirmLoading=true
      try {
        const { item, form } = this
        let params = await form.validateFields()
        params.course_cate_id = this.course_cate_id
        if (params) {
          if (item) {
            params.course_id = item.course_id
          }
          let res = await this.$store.dispatch(item ? 'cCategoryCoursesUpdateAction' : 'cCategoryCoursesAddAction', { data: params })
          if(res) {
            form.resetFields()
            this.parent.hideEditModal(1)
          }
        }
      } catch {
      }
			this.confirmLoading=false
    },
    handleCancel() {
      this.parent.hideEditModal(0)
    }
  }
}
</script>
