<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="700px"
    @ok="handleOk" @cancel="handleCancel">
    <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
    <a-form :form="form">
      <a-form-item v-for="(item, index) of formData" :key="index" class="course-edit-item" :label="item.label" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]"/>
        <a-input-number style="width:100%" :min="0" :step="0.5" v-if="item.type === 'numbers'" precision="1" v-decorator="[item.name, { rules: item.rules }]"/>
        <a-input-number style="width:100%" :min="0" v-if="item.type === 'number'" v-decorator="[item.name, { rules: item.rules }]"/>
        <a-input v-if="item.type === 'color'" v-model="color">
          <swatches slot="addonBefore" class="color-input"
              v-model="color"
              show-fallback
              popover-to="right"
            ></swatches>
        </a-input>
        <div v-if="item.type === 'upload'" v-decorator="[item.name, { rules: item.rules }]" style="display: inline-block; position: relative;" @click="showImagesModal(item.name)">
          <LImg v-if="imageUrl" :src="imageUrl" style="width: 120px; height: 60px;"/>
          <div v-if="imageUrl" class="close" @click="closeImg($event)">&times;</div>
          <a-button v-if="!imageUrl" type="dashed" icon="file-image">选择图片</a-button>
        </div>
        <a-date-picker v-if="item.type === 'date'" style="width: 100%"  v-decorator="[item.name, { rules: item.rules }]" format="YYYY-MM-DD"/>
        <a-radio-group v-decorator="[item.name]" v-else-if="item.type === 'radio'">
          <a-radio v-for="d in item.items.data" :key='d.value' :value="d.value">
            {{d.label}}
          </a-radio>
        </a-radio-group>
        <a-radio-group v-decorator="[item.name]" :disabled="isEdit === 1" v-else-if="item.type === 'radios'">
          <a-radio v-for="d in item.items.data" :key='d.value' :value="d.value">
            {{d.label}}
          </a-radio>
        </a-radio-group>
        <!-- <a-select v-else-if="item.type === 'select'" placeholder="请选择"
          v-decorator="[item.name, { rules: item.rules }]" mode="multiple" @change="handleSelect">
          <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
        </a-select> -->
        <a-tree-select
          v-else-if="item.type === 'select'"
          v-decorator="[item.name, { rules: item.rules }]"
          style="width: 100%"
          dropdownClassName='followTreeSelect'
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          :treeDefaultExpandedKeys='[0]'
          tree-checkable
          treeNodeFilterProp="title"
          :show-checked-strategy="SHOW_PARENT"
          search-placeholder="Please select">
            <a-tree-select-node v-for="(d, index) of seleteItems[item.items.data]" :key="d.studio_id" :value="d.studio_id" :title="d.studio_name">
              <a-tree-select-node v-for="(dc, ind) of d.children" :key="dc.studio_id" :value="dc.studio_id" :title="dc.studio_name"></a-tree-select-node>
            </a-tree-select-node>
        </a-tree-select>

        <a-textarea v-else-if="item.type === 'textarea'" :rows="4"
          v-decorator="[item.name, { rules: item.rules }]"/>
      </a-form-item>
    </a-form>
    <ImageModal v-if="imagesVisible"/>
  </a-modal>
</template>
<style lang="scss">
.vue-swatches__trigger {
  width: 28px!important;
  height: 28px!important;
}
.ant-form-item-with-help{
  margin-bottom: 5px !important;
}
</style>

<script>
const formData = [
  {
    name: 'category_cover',
    label: '科目图片',
    type: 'upload',
    rules: [{ required: true, message: '请选择科目图片!' }]
  },
  {
    name: 'category_title',
    label: '科目名称',
    type: 'input',
    rules: [{ required: true, message: '请输入科目名称!' }]
  },
  // {
  //   name: 'color',
  //   label: '排课颜色',
  //   type: 'color',
  //   rules: []
  // },
  {
    name: 'course_cost',
    label: '课时消耗',
    type: 'numbers',
    rules: [{ required: true, message: '请输入课时消耗!' }]
  },
  {
    name: 'category_type',
    label: '科目类型',
    type: 'radios',
    rules: [],
    items:{
      data:[
        {label:'一对多',value:1},
        {label:'一对一',value:2}
      ]
    }
  },
  {
    name: 'is_private',
    label: '是否公开',
    type: 'radio',
    rules: [],
    items:{
      data:[
        {label:'是',value:0},
        {label:'否',value:1}
      ]
    }
  },
  {
    name: 'course_times',
    label: '单课时长(分钟)',
    type: 'number',
    rules: [{ required: true, message: '请输入单课时长!' }]
  },
  // {
  //   name: 'course_count',
  //   label: '包含课程数',
  //   type: 'number',
  //   rules: [{ required: true, message: '请输入包含课程数!' }]
  // },
  {
    name: 'studio',
    label: '应用校区',
    type: 'select',
    rules: [{ required: true, message: '请选择应用校区!' }],
    items:{
      data:'studios',
      label: 'studio_name',
      value: 'studio_id'
    }
  },
  {
  name: 'category_desc',
  label: '科目介绍',
  type: 'textarea',
  rules: []
  },
]
import ImageModal from '@/views/modal/images'
import url from '@/utils/URL'
import Swatches from 'vue-swatches'
import "vue-swatches/dist/vue-swatches.min.css"
import { TreeSelect } from 'ant-design-vue'
const SHOW_PARENT = TreeSelect.SHOW_PARENT

export default {
  name: 'EditModal',
  inject: ['parent'],
  props: {
    item: Object,
    isEdit:Number
  },
  provide() {
    return {
      parent: this
    }
  },
  components: {
    Swatches,
    ImageModal,
  },

  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 15 },
      ModalTitle: '新增科目',
      visible: false,
      loading: false,
      confirmLoading: false,
      formData,
      form: this.$form.createForm(this),
      imageRoot: url.imageRoot,
      imagesVisible: false,
      imageUrl: '',
      color: '',
      seleteItems:{
        studios:[],
      },
      SHOW_PARENT,
      showSelect:false
    }
  }, 

  async created() {
    this.visible = true
    this.getStudio()
    if (this.item) {
      this.loading = true
      this.ModalTitle = '编辑科目'
      await this.$nextTick()
      let item = await this.getDetail(this.item.id)
      let formFields = {}
      for (let d of formData) {
        formFields[d.name] = item[d.name]
      }
      this.color = item.color
      this.imageUrl = item.category_cover
      delete formFields.color
      this.form.setFieldsValue(formFields)
    }else{
      this.form.setFieldsValue({is_private:0,category_type:1})
    }
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    async getDetail(id) {
      let res = await this.$store.dispatch('cCategoryDetailAction', {data: {id}})
      this.loading = false
      return res.data
    },
    async getStudio() {
      let res = await this.$store.dispatch('cCategoryStudiosAction', {})
      this.seleteItems.studios = res.data
    },
    async handleOk() {
      try {
        const { item, form } = this
        let params = await form.validateFields()
        if (params) {
          if (item) {
            params.id = item.id
          }
          params.category_cover = this.imageUrl
          params.color = this.color
          this.confirmLoading=true
          await this.$store.dispatch(item ? 'cCategoryUpdateAction' : 'cCategoryAddAction', { data: params })
            .then(res=>{
              this.$message.success('操作成功!')
              form.resetFields()
              this.parent.hideEditModal(1)
            })
            .catch(err=>{
              this.confirmLoading=false
            })
        }
      } catch { 
      }
      
    },
    handleCancel() {
      this.parent.hideEditModal(0)
    },

    showImagesModal(imageKey) {
      this.imageKey = imageKey
      this.imagesVisible = true
    },
    closeImg(e) {
      e.stopPropagation()
      this.imageUrl = ''
      this.form.setFieldsValue({category_cover:null})
    },
    hideImagesModal(image) {
      if (image) {
        console.log(image)
        this.imageUrl = image.path
        this.form.setFieldsValue({category_cover:image.path})
      }
      this.imagesVisible = false
    },
  }
}
</script>
